<template>
    <div class="text-center">
        <h1>Vos slimes</h1>
        <h5>Cette onglet reprend l'univers du jeu Slime Rancher</h5>
        <slime-blocks/>
    </div>
</template>

<script>
    const SlimeBlocks = () => import('@/components/slime/SlimeBlocks');

    export default {
        name: "MobileSlimeRanch",
        components: {SlimeBlocks}
    }
</script>